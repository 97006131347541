import get from 'lodash/get'
import React from 'react'
import LazyLoad from 'react-lazyload'
import startCase from 'lodash/startCase'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {
  JsonLdItemList,
  ScrollToTopOfPage,
  SEOMetaTags,
  SitePostSummary,
} from 'blog-components'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import BlogPostDetail from '../fragments/BlogPostDetailFragment'

class CategoryPageTemplate extends ScrollToTopOfPage {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.posts')
    const site = get(this, 'props.data.site')
    const category = get(this, 'props.pageContext.category')
    const path = get(this, 'props.location.pathname')

    const categoryStartCase = startCase(category.toLowerCase())

    let pageLinks = []
    let postSummaries = []

    if (posts !== undefined) {
      posts.forEach((element, i) => {
        postSummaries.push(
          <LazyLoad height={500} offset={500} once={true} key={i}>
            <SitePostSummary
              title={element.post.title}
              slug={element.post.slug}
              authors={element.post.authors}
              datePublished={element.post.publishDate}
              dateLastModified={element.post.updatedAt}
              description={
                element.post.description.childMarkdownRemark.rawMarkdownBody
              }
              shareImage={element.post.shareImage.fluid}
            />
          </LazyLoad>
        )
        pageLinks.push(site.siteMetadata.siteUrl + element.post.slug)
      })
    } else {
      postSummaries.push(<span>None found</span>)
    }

    return (
      <Layout>
        <SEOMetaTags
          path={path}
          site={site}
          title={categoryStartCase + ' Articles'}
          description={
            'A collection of our articles in the category ' + category
          }
          imageUrl={site.siteMetadata.siteUrl + '/main-logo.png'}
          structuredData={JsonLdItemList.generate(pageLinks)}
        />
        <div className="container fadein-3" data-emergence="visible">
          <div className="row justify-content-md-center text-center pt-5 px-3">
            <div className="col-md-10">
              <h1 className="display-3">{categoryStartCase}</h1>
            </div>
          </div>
          <div className="row justify-content-md-center text-center">
            <div className="col-md-10 py-5">{postSummaries}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CategoryPageTemplate

export const pageQuery = graphql`
  query PostsByCategory($category: [String!]) {
    logo: file(relativePath: { eq: "main-logo.png" }) {
      publicURL
    }
    site {
      ...SiteInformation
    }
    allContentfulBlogPost(
      sort: { order: DESC, fields: [publishDate] }
      filter: { categories: { in: $category } }
    ) {
      posts: edges {
        post: node {
          ...BlogPostDetail
        }
      }
    }
  }
`
